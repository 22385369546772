<template>
  <div>
    <img src="@/assets/logo-altera.png" srcset="
        @/assets/logo-altera-mobile.png 100w,
        @/assets/logo-altera.png        300w
      " class="logo-altera" />
  </div>
</template>

<script>
export default {}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logo-altera {
  width: 250px;
  margin-top: 15rem;
}
</style>
