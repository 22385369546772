<template>
  <div>
    <LogoAltera></LogoAltera>
  </div>
  <div class="container-title">
    <span class="title">Prueba nuestra <br> Tecnología</span>
  </div>
  <div class="container-link" @click="goToForm">
    <span class="sub-title link">Haz click para comenzar</span>
  </div>
</template>

<script>
import LogoAltera from '@/components/LogoAltera.vue'

export default {
  name: 'IntroComponent',
  components: { LogoAltera },
  methods: {
    goToForm: function () {
      this.$router.push('/form')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-title {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.title {
  color: var(--color-01);
  text-transform: uppercase;
  font-size: 54px;
  font-family: var(--font-family-1);
  line-height: 80px;
}

.container-link {
  margin: 50px auto;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}


.sub-title {
  color: var(--color-01);
  text-transform: uppercase;
  font-size: 20px;
  font-family: var(--font-family-2);
}
</style>
