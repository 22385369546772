<template>
  <div class="container-footer">
    <span class="title">www.altera.cl</span>
  </div>
</template>

<script>
export default {
  name: 'FooterComponent',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-footer {
  background-color: var(--color-05);
  color: var(--color-01);
  margin-top: 20px;
  vertical-align: middle;
  padding: 5px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.title {
  font-size: 20px;
  font-weight: bold;
  font-family: var(--font-family-3);
}


</style>
