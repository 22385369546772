<template>
  <div class="container-logo">
    <LogoAlteraBankBlack></LogoAlteraBankBlack>
  </div>

  <div class="container-body">
    <div class="container-content">
      <div class="container-text">
        <span class="title">¡Ya estamos preparando tu avance!</span>
        <p class="paragraph">
          Enviaremos toda la información a tu correo para que puedas disfrutar
          de tu dinero.
        </p>
        <div class="container-btn" @click="goTo">
          <button class="btn">Volver al inicio</button>
        </div>
      </div>
    </div>
    <div class="container-title-options">
      <span class="title-options">Conoce las ventajas:</span>
    </div>

    <div class="container-cards">
      <ImageOption :image="hand"></ImageOption>
      <ImageOption :image="money"></ImageOption>
      <ImageOption :image="percentage"></ImageOption>
    </div>
  </div>

  <div>
    <Footer></Footer>
  </div>
</template>

<script setup>
const hand = {
  title: 'Dinero rápido y muy fácil',
  subTitle:
    'Obtener dinero directo en tu cuenta corriente o el efectivo que necesitas',
  option: 'hand',
  image: require('@/assets/hand.png'),
  backgroundColor: 'backgroundColor4',
  containerDisplay: 'containerDisplayFlex',
  containerTextAlign: 'textAlignLeft',
  titleClass: 'title-1',
  containerMargin: 'margin-20',
}

const money = {
  title: 'Liquidez en tu cuenta bancaria',
  subTitle: 'Asumir deudas o pagos importantes que estimes convenientes.',
  option: 'money',
  image: require('@/assets/money-hand.png'),
  backgroundColor: 'backgroundColor5',
  containerDisplay: 'containerDisplayFlex',
  containerTextAlign: 'textAlignLeft',
  titleClass: 'title-1',
  containerMargin: 'margin-20',
}

const percentage = {
  title: 'Casi 100% del cupo de tu tarjeta',
  subTitle:
    'Dispones hasta del 95% del cupo disponible en tu Tarjeta de Crédito Altera.',
  option: 'percentage',
  image: require('@/assets/percentage.png'),
  backgroundColor: 'backgroundColor6',
  containerDisplay: 'containerDisplayFlex',
  containerTextAlign: 'textAlignLeft',
  titleClass: 'title-1',
  containerMargin: 'margin-20',
}
</script>

<script>
import LogoAlteraBankBlack from '@/components/LogoAlteraBankBlack.vue'
import ImageOption from '@/components/ImageOption.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'AdvantagesComponent',
  components: { LogoAlteraBankBlack, ImageOption, Footer },
  methods: {
    goTo: function () {
      this.$router.push('/')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-logo {
  text-align: right;
  padding: 30px 30px 20px 0;
}
.container-body {
  height: 65%;
  padding-bottom: 50px;
}

.container-banner-titles {
  display: grid;
  text-align: left;
}

.container-images {
  display: inline-block;
}
.container-content {
  text-align: center;
}

.container-cards {
  display: flex;
  padding: 0 10px 0 0;
}

.image {
  height: 300px;
  width: 200px;
}

.container-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}
.btn {
  border-radius: 20px;
  cursor: pointer;
  text-transform: uppercase;
  border: none;
  padding: 10px 25px;
  font-weight: bold;
  font-size: 14px;
  font-family: var(--font-family-3);
  margin: 50px 10px;
  color: var(--color-01) !important;
  background-color: var(--color-04);
}

.title {
  font-size: 30px;
  font-family: var(--font-family-3);
  font-weight: bold;
  display: block;
  line-height: 30px;
}
.container-text {
  padding: 50px 25px;
}
.paragraph {
  font-size: 13px;
  font-family: var(--font-family-3);
  padding: 20px 0px 10px 0px;
}

.title-options {
  font-size: 30px;
  color: var(--color-07);
  font-weight: 900;
}

.container-title-options {
  text-align: center;
  padding: 10px 0px 50px 0px;
}
</style>
