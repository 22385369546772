<template>
  <div class="container-logo">
    <LogoAlteraBankBlack></LogoAlteraBankBlack>
  </div>

  <div class="container-body">
    <div class="container-area">
      <div class="container-label">
        <span class="label">Estás pagando con:</span>
        <img src="@/assets/logo-altera-pay.png" class="logo-altera-pay" />
      </div>

      <div class="container-form">
        <label for="ammount" class="label">Monto a pagar:</label>
        <input
          type="text"
          class="input"
          id="ammount"
          name="ammount"
          :value="ammount"
          disabled
        />
      </div>
    </div>

    <div class="container-btn">
      <button class="btn backgroundColor6" @click="openModalConfirm(), goTo()">
        Confirmar
      </button>
      <button class="btn backgroundColor4" @click="openModalCancel(), goTo()">
        Anular compra
      </button>
    </div>
  </div>

  <Modal v-if="showModalConfirm" :image="imageConfirm"></Modal>

  <Modal v-if="showModalCancel" :image="imageCancel"></Modal>
  <div>
    <Footer></Footer>
  </div>
</template>

<script setup></script>

<script>
import LogoAlteraBankBlack from '@/components/LogoAlteraBankBlack.vue'
import Footer from '@/components/Footer.vue'
import Modal from '@/components/SmsModal.vue'

export default {
  name: 'ResumeComponent',
  components: { LogoAlteraBankBlack, Footer, Modal },
  data: function () {
    return {
      showModalConfirm: false,
      showModalCancel: false,
      ammount: `$ ${this.$router.currentRoute.value.params.ammount}`,
      imageConfirm: require('@/assets/sms_confirm_payment.png'),
      imageCancel: require('@/assets/sms_' +
        this.$router.currentRoute.value.params.option +
        '.png'),
    }
  },
  methods: {
    openModalConfirm: function () {
      this.showModalConfirm = true
    },
    openModalCancel: function () {
      this.showModalCancel = true
    },
    goTo: function () {
      setTimeout(() => {
        this.$router.push('/')
      }, 10000)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-label {
  padding: 10px;
  display: grid;
  justify-content: center;
}
.container-logo {
  text-align: right;
  padding: 30px 30px 20px 0;
}
.container-body {
  height: 65%;
  padding-bottom: 50px;
  text-align: center;
}

.container-btn {
  width: 100%;
  display: grid;
  justify-content: center;
  margin: 50px 0;
}
.btn {
  border-radius: 20px;
  cursor: pointer;
  text-transform: uppercase;
  border: none;
  font-weight: bold;
  font-size: 14px;
  font-family: var(--font-family-3);
  margin: 5px;
  color: var(--color-01) !important;
}

.backgroundColor4 {
  background-color: var(--color-04) !important;
}
.backgroundColor6 {
  background-color: var(--color-06) !important;
}

.label {
  display: inline-block;
  color: var(--color-07);
  padding: 0;
  font-family: var(--font-family-3);
  font-size: 20px;
  font-weight: bold;
}

.input {
  font-size: 20px;
  padding: 5px 10px;
  background-color: var(--color-08) !important;
  margin: 9px;
  border-radius: 15px;
  border: none;
  outline: none;
  width: 100px;
  height: 25px;
  text-align: center;
  font-weight: bold;
}

.logo-altera-pay {
  width: 430px;
  height: 120px;
  margin: 20px;
}

.container-area {
  background-color: var(--color-08);
  text-align: center;
  padding: 20px;
  display: grid;
  border-radius: 30px;
  margin: 50px;
}

.container-form {
  text-align: center;
  padding: 10px 50px 50px 30px;
}
</style>
