<template>
  <div class="container-logo">
    <LogoAltera></LogoAltera>
  </div>
  <div class="container-title">
    <span class="title">¡Es momento de explorar!</span>
  </div>
  <div class="container-link" @click="goToStart">
    <span class="sub-title link">Haz click para comenzar</span>
  </div>
</template>

<script>
import LogoAltera from '@/components/LogoAltera.vue'
export default {
  name: 'EndingView',
  components: { LogoAltera },
  methods: {
    goToStart: function () {
      this.$router.push('/bank/home')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-logo {
  margin: 47px auto;
}

.container-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.title {
  color: var(--color-01);
  text-transform: uppercase;
  font-size: 50px;
  font-family: var(--font-family-1);
  letter-spacing: 2px;
  margin-top: 3rem;
  margin-bottom: 3rem;
  line-height: 80px;
}

.container-link {
  margin: 22px auto;
}

.link {
  text-decoration: underline;
  cursor: pointer;

}

.container-sub-title {
  margin: 0px auto 16px auto;
}

.sub-title {
  color: var(--color-01);
  text-transform: uppercase;
  font-size: 20px;
  font-family: var(--font-family-2);
}
</style>
