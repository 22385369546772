<template>
  <div class="container-banner">
    <div class="container-logo">
      <LogoAlteraBankWhite></LogoAlteraBankWhite>
    </div>
    <div class="container-banner-titles">
      <span class="title">Pide hoy tu avance</span>
      <span class="sub-title">Hazlo 100% online, en un par de clicks</span>
    </div>
  </div>
  <div class="container-body">
    <div class="container-content">
      <img src="@/assets/cash-advance.png" class="image" />
      <div class="container-text">
        <p class="content-title">
          La manera más fácil e inmediata de tener dinero
        </p>
        <p class="paragraph">
          Ingresa hoy a tu sitio privado y solicita tu Avance con abono directo
          a tu Cuenta Corriente o retira desde el cajero automático más cercano.
        </p>
        <div class="container-btn">
          <button class="btn">Beneficios</button>
        </div>
      </div>
    </div>

    <div class="container-area">
      <div class="container-form">
        <div class="container-title">
          <span class="title-1">Simula tu avance</span>
        </div>
        <div class="div">
          <label for="ammount" class="label">Monto a simular:</label>

          <input type="text" class="input" id="ammount" />
        </div>
        <div class="div">
          <label for="dues" class="label">Cuotas:</label>
          <select class="select" id="dues">
            <option>3</option>
            <option>6</option>
            <option>12</option>
          </select>
        </div>
        <div class="div">
          <label for="months" class="label">Meses referidos:</label>
          <select class="select" id="months">
            <option>No</option>
            <option>Sí, 1 mes</option>
            <option>Sí, 2 meses</option>
          </select>
        </div>

        <div class="container-btn-2" @click="openModal(), goTo()">
          <button class="btn-2">Simular</button>
        </div>
      </div>
    </div>
  </div>
  <Modal v-if="showModal" :image="image"></Modal>
  <div>
    <Footer></Footer>
  </div>
</template>

<script setup>
const image = require('@/assets/sms_code.png')
</script>

<script>
import LogoAlteraBankWhite from '@/components/LogoAlteraBankWhite.vue'
import Footer from '@/components/Footer.vue'
import Modal from '@/components/SmsModal.vue'
import { TIME_MESSAGES } from '@/config/app.config'

export default {
  name: 'HomeComponent',
  components: { LogoAlteraBankWhite, Footer, Modal },
  data: function () {
    return {
      showModal: false,
    }
  },
  methods: {
    openModal: function () {
      this.showModal = true
    },
    goTo: function () {
      setTimeout(() => {
        this.$router.push('/bank/cash-advance/code-sms')
      }, TIME_MESSAGES)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.input {
  padding: 5px 10px;
  background-color: var(--color-05) !important;
  margin: 9px;
  border-radius: 15px;
  border: none;
  outline: none;
  font-family: var(--font-family-2);
  text-transform: uppercase;
  font-size: 10px;
  width: 150px;
  height: 25px;
}
.select {
  padding: 5px 10px;
  background-color: var(--color-05) !important;
  margin: 5px;
  border-radius: 15px;
  border: none;
  outline: none;
  font-family: var(--font-family-2);
  font-size: 10px;
  width: 150px;
  height: 25px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('@/assets/select-arrow-mobile.png');
  background-repeat: no-repeat, repeat;
  background-position: right 1em top 50%, 0 0;
  background-size: 5px, 5px;
}
.select option {
  font-family: var(--font-family-2);
  font-size: 10px;
  font-weight: bold;
}
.label {
  font-size: 17px;
  font-family: var(--font-family-3);
}

p {
  margin: 10px;
  margin-bottom: 0;
}
.container-banner {
  height: 250px;
  width: 100%;
  background: url('@/assets/banner-cash-advance.png') no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  text-align: left;
}
.container-body {
  height: 65%;
  padding: 50px 0px;
}

.container-banner-titles {
  display: grid;
  text-align: left;
  padding: 10px 0 0 20px;
}

.title {
  font-family: var(--font-family-4);
  font-size: 20px;
  color: var(--color-01);
  font-weight: bold;
}

.title-1 {
  font-family: var(--font-family-3);
  font-size: 23px;
  color: var(--color-07);
  font-weight: bold;
}
.sub-title {
  font-family: var(--font-family-3);
  font-size: 10px;
  color: var(--color-01);
}
.container-logo {
  padding: 30px 0px 20px 20px;
}

.container-content {
  display: flex;
  padding: 50px;
  text-align: center;
}

.container-form {
  background-color: var(--color-08);
  text-align: left;
  padding: 20px 50px;
  border-radius: 20px;
}

.image {
  height: 200px;
  width: 125px;
}
.container-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0px 40px 0px;
}
.btn {
  color: var(--color-01);
  border-radius: 20px;
  cursor: pointer;
  text-transform: uppercase;
  border: none;
  font-weight: bold;
  padding: 5px 25px;
  font-family: var(--font-family-3);
  background-color: var(--color-06) !important;
  font-size: 12px;
}

.container-btn-2 {
  width: 100%;
  display: flex;
  justify-content: right;
  margin: 10px 0px;
  padding-right: 30px;
}
.btn-2 {
  color: var(--color-01);
  border-radius: 20px;
  cursor: pointer;
  text-transform: uppercase;
  border: none;
  font-weight: bold;
  padding: 5px 25px;
  font-family: var(--font-family-3);
  background-color: var(--color-04) !important;
  font-size: 12px;
}

.paragraph {
  font-size: 10px;
  font-family: var(--font-family-3);
}
.content-title {
  font-size: 17px;
  font-family: var(--font-family-3);
  font-weight: bold;
  line-height: 20px;
  margin-top: 0;
}
.container-text {
  padding: 10px 30px;
  text-align: justify;
  width: 50%;
}
.container-area {
  display: flex;
  justify-content: center;
  margin: 50px 0px;
}
.container-title {
  text-align: center;
}
</style>
