<template>
  <div class="container-logo">
    <LogoAlteraBankBlack></LogoAlteraBankBlack>
  </div>

  <div class="container-body">
    <div class="container-content">
      <div class="container-title">
        <span class="title">Resumen de tu compra</span>
      </div>
      <div class="container-area">
        <div class="container-form">
          <img :src="image" class="image" />
          <div class="container-text">
            <span class="text-1">{{ title1 }}</span>
            <span class="text-1-1">{{ title2 }}</span>
            <span class="text-4"><strong>Cantidad:</strong> 1</span>
            <span class="text-4"
              ><strong>Puntos:</strong
              >{{
                points.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
              }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="text-2">
      <span class="sub-title color-red">Puntos insuficientes.</span>
      <span class="sub-title">Diferencia a pagar: ${{ pesos }}</span>
    </div>
    <div class="text-3">
      <span class="sub-title">Puntos: {{ points }}</span>
      <span class="sub-title">Puntos disponibles: 1.000</span>
      <span class="sub-title"
        ><strong>A pagar: ${{ pesos }}</strong></span
      >
    </div>
    <div class="container-btn" @click="goTo">
      <button class="btn">pagar diferencia</button>
    </div>
  </div>
  <div>
    <Footer></Footer>
  </div>
</template>

<script setup></script>

<script>
import LogoAlteraBankBlack from '@/components/LogoAlteraBankBlack.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'ResumeComponent',
  components: { LogoAlteraBankBlack, Footer },
  data: function () {
    return {
      option: this.$router.currentRoute.value.params.option,
      image: require('@/assets/' +
        this.$router.currentRoute.value.params.option +
        '.png'),
      title1: this.$router.currentRoute.value.params.title1,
      title2: this.$router.currentRoute.value.params.title2,
      points: this.$router.currentRoute.value.params.points
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'),
      pesos: (this.$router.currentRoute.value.params.points - 1000)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'),
    }
  },

  methods: {
    goTo: function () {
      this.$router.push(`/bank/points/payment/${this.option}/${this.pesos}`)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-logo {
  text-align: right;
  padding: 30px 30px 20px 0;
}
.container-body {
  height: 65%;
  padding-bottom: 50px;
  text-align: center;
}
.title {
  font-size: 25px;
  font-family: var(--font-family-3);
  font-weight: bold;
  display: block;
  line-height: 30px;
}

.image {
  height: 100px;
  width: 100px;
  margin: 20px auto 0 auto;
}

.container-btn {
  width: 100%;
  display: grid;
  justify-content: center;
  padding: 10px;
}

.btn {
  border-radius: 20px;
  cursor: pointer;
  text-transform: uppercase;
  border: none;
  font-weight: bold;
  font-size: 14px;
  font-family: var(--font-family-3);
  color: var(--color-01) !important;
  background-color: var(--color-04);
}

.backgroundColor4 {
  background-color: var(--color-04) !important;
}

.container-content {
  text-align: center;
}
.container-title {
  padding: 0 30px;
}

.title {
  color: var(--color-07) !important;
}

.sub-title {
  color: var(--color-07) !important;
  font-size: 15px;
}
.container-form {
  height: 250px;
  background-color: var(--color-08);
  text-align: center;
  border-radius: 20px;
  display: inline-block;
  margin: 25px 50px;
}
.container-text {
  display: grid;
}

.text-1 {
  padding: 5px 30px;
  font-weight: bold;
  font-size: 15px;
  font-family: var(--font-family-3);
}
.text-1-1 {
  padding: 0px 30px;
  font-weight: bold;
  font-size: 15px;
  font-family: var(--font-family-3);
}

.text-2 {
  display: grid;
}

.text-3 {
  display: grid;
  text-align: center;
  font-family: var(--font-family-3);
  padding-left: 20px;
  margin: 20px 0px;
}

.text-4 {
  text-align: center;
  font-size: 12px;
  font-family: var(--font-family-3);
}
.color-red {
  color: red !important;
}
</style>
