<template>
  <Transition name="modal">
    <div v-if="show" class="modal">
      <div>
        <img :src="image" class="image-sms" @click="hide()" />
      </div>
    </div>
  </Transition>
</template>

<script>
//import { TIME_MESSAGES } from '@/config/app.config'
export default {
  props: { image: null },
  data: function () {
    return {
      show: true,
    }
  },
  methods: {
    hide: function () {
      this.show = false
    },
  },

  mounted: function () {
    // window.setInterval(() => {
    //   this.show = false
    // }, TIME_MESSAGES)
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal {
  /* position: fixed;
  z-index: 9998;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 50%;
  background-color: transparent;
  display: table;
  transition: opacity 0.3s ease; */

  position: fixed;
  z-index: 9998;
  top: 50%;
  width: 60%;
  height: 40%;
  background-color: transparent;
  display: table;
  transition: opacity 0.3s ease;
  left: 50%;
}

.image-sms {
  width: 60%;
  height: 40%;
}
</style>
