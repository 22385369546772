<template>
  <div>
    <div class="container-logo">
      <LogoAlteraBankBlack></LogoAlteraBankBlack>
    </div>

    <div class="container-body">
      <div>
        <div>
          <span class="title">Abre tu Cuenta Corriente</span>
        </div>

        <img src="@/assets/open-account.png" class="image" />
      </div>

      <Modal v-if="showModalContact" :image="imageContact"></Modal>
      <Modal v-if="showModalCancel" :image="imageCancel"></Modal>

      <div class="container-btn">
        <button
          class="btn backgroundColor6"
          @click="openModalContact(), goTo()"
        >
          contactar un ejecutivo/a
        </button>
        <button class="btn backgroundColor4" @click="openModalCancel(), goTo()">
          Cancelar apertura de cuenta
        </button>
      </div>
    </div>
    <div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script setup>
const imageContact = require('@/assets/sms_1.png')
const imageCancel = require('@/assets/sms_2.png')
</script>

<script>
import LogoAlteraBankBlack from '@/components/LogoAlteraBankBlack.vue'
import Footer from '@/components/Footer.vue'
import Modal from '@/components/SmsModal.vue'
import { TIME_MESSAGES } from '@/config/app.config'
export default {
  name: 'OpenAccountComponent',
  components: { LogoAlteraBankBlack, Footer, Modal },
  data: function () {
    return {
      showModalContact: false,
      showModalCancel: false,
    }
  },
  methods: {
    openModalContact: function () {
      this.showModalCancel = false
      this.showModalContact = true
    },
    openModalCancel: function () {
      this.showModalContact = false
      this.showModalCancel = true
    },
    goTo: function () {
      setTimeout(() => {
        this.$router.push('/')
      }, TIME_MESSAGES)
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  background-color: transparent;
  display: table;
  transition: opacity 0.3s ease;
}

.image-sms {
  width: 80%;
  height: 80%;
}

.container-logo {
  text-align: right;
  padding: 30px 30px 20px 0;
}

.container-body {
  height: 65%;
  padding: 50px 0px;

  text-align: center;
}
.image {
  height: 170px;
  width: 280px;
  margin: 50px auto;
}

.container-btn {
  width: 100%;
  display: grid;
  justify-content: center;

}
.btn {
  border-radius: 20px;
  cursor: pointer;
  text-transform: uppercase;
  border: none;
  padding: 10px 25px;
  font-weight: bold;
  font-size: 14px;
  font-family: var(--font-family-3);
  margin: 10px;
  color: var(--color-01) !important;
}

.backgroundColor4 {
  background-color: var(--color-04) !important;
}
.backgroundColor6 {
  background-color: var(--color-06) !important;
}

.title {
  color: var(--color-07) !important;
  font-size: 30px;
  font-weight: bold;
  font-family: var(--font-family-4);
}


</style>
