<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {
    handleStyles() {
      if (['/', '/form', '/ending'].includes(this.$route.path))
        document.body.className = 'intro'
      else if (document.body.classList.contains('intro'))
        document.body.className = 'landing'
    },
  },
  mounted() {
    this.handleStyles()
  },
  watch: {
    $route() {
      this.handleStyles()
    },
  },
}
</script>

<style>
.intro {
  background: url(@/assets/background.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  text-align: center;
  background-color: #f47524;
}
.landing {
  text-align: center;
  width: 100%;
  display: grid;
}
</style>
