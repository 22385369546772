<template>
  <div class="container-logo">
    <LogoAlteraBankBlack></LogoAlteraBankBlack>
  </div>

  <div class="container-body">
    <div class="container-area">
      <div class="container-form">
        <div>
          <span class="title"
            >Introduce el código que enviamos a tu celular</span
          >
        </div>

        <div class="container-div">
          <span class="title-1">Código:</span>

          <div class="div">
            <input
              id="input_1"
              type="text"
              class="input"
              required
              v-model="input_1"
              v-on:keypress="validatePhoneNumber($event)"
              maxlength="1"
            />
          </div>
          <div class="div">
            <input
              id="input_2"
              type="text"
              class="input"
              required
              v-model="input_2"
              v-on:keypress="validatePhoneNumber($event)"
              maxlength="1"
            />
          </div>
          <div class="div">
            <input
              id="input_3"
              type="text"
              class="input"
              required
              v-model="input_3"
              v-on:keypress="validatePhoneNumber($event)"
              maxlength="1"
            />
          </div>
          <div class="div">
            <input
              id="input_4"
              type="text"
              class="input"
              required
              v-model="input_4"
              v-on:keypress="validatePhoneNumber($event)"
              maxlength="1"
            />
          </div>
        </div>
        <span class="title-2">¿No recibiste el código?</span>

        <div class="container-btn">
          <button class="btn backgroundColor6" @click="openModal()">
            Volver a enviar SMS
          </button>
          <button class="btn backgroundColor4" @click="goTo()">
            Enviar código
          </button>
        </div>
      </div>
    </div>
  </div>
  <Modal v-if="showModal" :image="image"></Modal>
  <div>
    <Footer></Footer>
  </div>
</template>

<script setup>
const image = require('@/assets/sms_code.png')
</script>

<script>
import LogoAlteraBankBlack from '@/components/LogoAlteraBankBlack.vue'
import Footer from '@/components/Footer.vue'
import Modal from '@/components/SmsModal.vue'
export default {
  name: 'CodeSmsComponent',
  components: { LogoAlteraBankBlack, Footer, Modal },
  data: function () {
    return {
      input_1: '0',
      input_2: '8',
      input_3: '1',
      input_4: '',
      showModal: false,
    }
  },
  methods: {
    showToast(mensaje) {
      this.$toast.show(mensaje, {
        position: 'top-right',
        duration: 1500,
        queue: false,
        max: 1,
      })
    },
    validatePhoneNumber(e) {
      let char = String.fromCharCode(e.keyCode)
      if (/[0-9]+/.test(char)) return true
      else e.preventDefault()
    },
    openModal: function () {
      this.showModal = true
    },
    goTo: function () {
      if (
        this.input_1 === '0' &&
        this.input_2 === '8' &&
        this.input_3 === '1' &&
        this.input_4 === '9'
      )
        this.$router.push('/bank/cash-advance/advantages')
      else this.showToast('El código ingresado es inválido')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-logo {
  text-align: right;
  padding: 30px 30px 20px 0;
}
.container-body {
  height: 65%;
  padding-bottom: 50px;

  text-align: center;
}

.image {
  height: 200px;
  width: 300px;
  margin: 20px;
}

.container-btn {
  width: 100%;
  display: grid;
  justify-content: center;
  margin-bottom: 30px;
}
.btn {
  border-radius: 20px;
  cursor: pointer;
  text-transform: uppercase;
  border: none;
  padding: 10px 25px;
  font-weight: bold;
  font-size: 14px;
  font-family: var(--font-family-3);
  margin: 10px;
  color: var(--color-01) !important;
}

.backgroundColor4 {
  background-color: var(--color-04) !important;
}
.backgroundColor6 {
  background-color: var(--color-06) !important;
}

.title {
  font-family: var(--font-family-3);
  font-size: 20px;
  color: var(--color-07);
  font-weight: bold;
  line-height: 20px;
}

.title-1 {
  font-family: var(--font-family-3);
  font-size: 17px;
  color: var(--color-07);
  font-weight: bold;
}

.title-2 {
  font-family: var(--font-family-3);
  font-size: 15px;
  color: var(--color-07);
  font-weight: bold;
}
.div {
  background-color: var(--color-06);

  border-radius: 5px;
  margin: 0 5px 0 5px;
}

.input {
  padding: 5px;
  background-color: var(--color-06) !important;
  border-radius: 15px;
  border: none;
  outline: none;
  font-family: var(--font-family-2);
  text-transform: uppercase;
  font-size: 15px;
  height: 30px;
  width: 30px;
  text-align: center;
}

.container-area {
  padding: 100px 25px;
  justify-content: center;
  margin: auto;
}

.container-form {
  padding: 25px 25px 10px 25px;
  background-color: var(--color-08);
  text-align: center;
  border-radius: 30px;
}

.container-div {
  display: flex;
  justify-content: center;
  padding: 20px 0px;
}
</style>
