<template>
  <div :class="image.containerDisplay">
    <div
      @click="image.buttonTitle && goTo(image.goTo)"
      v-bind:class="image.buttonTitle ? 'pointer' : ''"
    >
      <img v-if="image.image" :src="image.image" class="image" />
    </div>
    <div class="container-tex">
      <div
        class="container-titles"
        :class="[image.containerTextAlign, image.containerMargin]"
      >
        <span :class="image.titleClass">{{ image.title1 }}</span>
        <span :class="image.titleClass">{{ image.title2}}</span>
        <span class="sub-title">{{ image.subTitle }}</span>
      </div>

      <div
        v-if="image.buttonTitle"
        class="container-btn"
        :class="image.containerTextAlign"
        @click="goTo(image.goTo)"
      >
        <button class="btn" :class="image.backgroundColorButton">
          {{ image.buttonTitle }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: null,
  },
  methods: {
    goTo: function (url) {
      this.$router.push(
        `${url}/${
          this.image.dinamycUrl
            ? `${this.image.option}/${this.image.title1}/${this.image.title2}/${this.image.points}`
            : ''
        }`
      )
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-tex {
  display: grid;
  justify-content: center;
}
.textAlignCenter {
  text-align: center;
  justify-content: center;
}

.textAlignLeft {
  text-align: left;
  justify-content: left;
  margin-left: 0px !important;
}
.containerDisplayFlex {
  justify-items: center;
  width: 33.33%;
  padding: 10px;
}
.containerDisplayGrid {
  display: grid;
  justify-items: center;
}
.backgroundColor4 {
  background-color: var(--color-04) !important;
}
.backgroundColor5 {
  background-color: var(--color-05) !important;
}
.backgroundColor6 {
  background-color: var(--color-06) !important;
}

.image {
  width: 100px;
  height: 100px;

  border-radius: 10px;
}

.pointer {
  cursor: pointer;
}
.title-image {
  color: var(--color-01);
  font-weight: 900;
  text-transform: uppercase;
  cursor: pointer;
}

.container-titles {
  display: grid;
  text-align: center;
}
.margin-10 {
  margin: 10px auto 20px auto;
}

.margin-20 {
  margin: 20px auto 20px auto;
}
.title-1 {
  font-size: 19px;
  color: var(--color-07);
  font-family: var(--font-family-3);
  font-weight: bold;
  line-height: 20px;
}

.title-2 {
  font-size: 11px;
  color: var(--color-07);
  font-family: var(--font-family-3);
  font-weight: bold;
}
.sub-title {
  color: var(--color-07);
  font-size: 10px;
  font-family: var(--font-family-3);
}

.container-btn {
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
}
.btn {
  color: var(--color-01);
  border-radius: 20px;
  cursor: pointer;
  text-transform: uppercase;
  border: none;
  font-weight: bold;
  padding: 3px 20px;
  font-family: var(--font-family-3);
  font-size: 10px;
}
</style>
