<template>
  <div class="container-banner">
    <div class="container-logo">
      <LogoAlteraBankWhite></LogoAlteraBankWhite>
    </div>
    <div class="container-banner-titles">
      <span class="title">Canjea hoy tus<br />puntos Altera Bank</span>

      <span class="sub-title">Tenemos muchos productos a tu disposición</span>
    </div>
  </div>
  <div class="container-body">
    <div class="container-content">
      <div class="container-text">
        <span class="content-title"
          >Usa tus puntos en miles de productos y experiencias.</span
        >
        <p class="paragraph">
          Si no te alcanzan los puntos, puedes pagar la diferencia con tu
          tarjeta de crédito en 3 cuotas sin interés.
        </p>
      </div>
    </div>

    <div class="container-cards">
      <ImageOption :image="cine"></ImageOption>
      <ImageOption :image="market"></ImageOption>
      <ImageOption :image="chocolate"></ImageOption>
    </div>
    <div class="container-cards">
      <ImageOption :image="vod"></ImageOption>
      <ImageOption :image="sky"></ImageOption>
      <ImageOption :image="tv"></ImageOption>
    </div>
  </div>

  <div>
    <Footer></Footer>
  </div>
</template>

<script setup>
const cine = {
  title1: '2 entradas en',
  title2: 'Cine 2D + 1 POP grande',
  subTitle: '4.000 puntos',
  points: 4000,
  option: 'cine',
  backgroundColor: 'backgroundCineMobile',
  buttonTitle: 'canjear',
  backgroundColorButton: 'backgroundColor4',
  containerDisplay: 'containerDisplayFlex',
  containerTextAlign: 'textAlignLeft',
  titleClass: 'title-2',
  containerMargin: 'margin-20',
  goTo: '/bank/points/resume',
  dinamycUrl: true,
  image: require('@/assets/cine.png'),
}
const market = {
  title1: '$10.000 de descuento',
  title2: 'en AlteraMarket',
  subTitle: '3.000 puntos',
  points: 3000,
  option: 'market',
  backgroundColor: 'backgroundMarketMobile',
  buttonTitle: 'canjear',
  backgroundColorButton: 'backgroundColor5',
  containerDisplay: 'containerDisplayFlex',
  containerTextAlign: 'textAlignLeft',
  titleClass: 'title-2',
  containerMargin: 'margin-20',
  goTo: '/bank/points/resume',
  dinamycUrl: true,
  image: require('@/assets/market.png'),
}
const chocolate = {
  title1: 'Disfruta $5.000 en',
  title2: '"La casa del Chocolate"',
  subTitle: '3.500 puntos',
  points: 3500,
  option: 'chocolate',
  backgroundColor: 'backgroundChocolateMobile',
  buttonTitle: 'canjear',
  backgroundColorButton: 'backgroundColor6',
  containerDisplay: 'containerDisplayFlex',
  containerTextAlign: 'textAlignLeft',
  titleClass: 'title-2',
  containerMargin: 'margin-20',
  goTo: '/bank/points/resume',
  dinamycUrl: true,
  image: require('@/assets/chocolate.png'),
}

const vod = {
  title1: 'Suscripción por 3 meses',
  title2: 'a Altera VOD',
  subTitle: '6.000 puntos',
  points: 6000,
  option: 'vod',
  backgroundColor: 'backgroundVodMobile',
  buttonTitle: 'canjear',
  backgroundColorButton: 'backgroundColor4',
  containerDisplay: 'containerDisplayFlex',
  containerTextAlign: 'textAlignLeft',
  titleClass: 'title-2',
  containerMargin: 'margin-20',
  goTo: '/bank/points/resume',
  dinamycUrl: true,
  image: require('@/assets/vod.png'),
}
const sky = {
  title1: 'Pase completo de temporada',
  title2: 'en Sky Santiago',
  subTitle: '10.000 puntos',
  points: 10000,
  option: 'sky',
  backgroundColor: 'backgroundSkyMobile',
  buttonTitle: 'canjear',
  backgroundColorButton: 'backgroundColor5',
  containerDisplay: 'containerDisplayFlex',
  containerTextAlign: 'textAlignLeft',
  titleClass: 'title-2',
  containerMargin: 'margin-20',
  goTo: '/bank/points/resume',
  dinamycUrl: true,
  image: require('@/assets/sky.png'),
}
const tv = {
  title1: 'Televisor Pantalla',
  title2: 'LED 55" Technot',
  subTitle: '7.000 puntos',
  points: 7000,
  option: 'tv',
  backgroundColor: 'backgroundTvMobile',
  buttonTitle: 'canjear',
  backgroundColorButton: 'backgroundColor6',
  containerDisplay: 'containerDisplayFlex',
  containerTextAlign: 'textAlignLeft',
  titleClass: 'title-2',
  containerMargin: 'margin-20',
  goTo: '/bank/points/resume',
  dinamycUrl: true,
  image: require('@/assets/tv.png'),
}
</script>

<script>
import LogoAlteraBankWhite from '@/components/LogoAlteraBankWhite.vue'
import ImageOption from '@/components/ImagePoints.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'HomeComponent',
  components: { LogoAlteraBankWhite, ImageOption, Footer },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-banner {
  height: 250px;
  width: 100%;
  background: url('@/assets/banner-points.png') no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  text-align: left;
}

.container-logo {
  padding: 30px 0px 20px 20px;
}
.container-body {
  height: 65%;
  padding: 50px 0px;
}

.container-banner-titles {
  display: grid;
  text-align: left;
  padding: 0 0 0 20px;
}

.container-images {
  display: inline-block;
}
.container-content {
  margin: auto;
  text-align: center;
  display: block;
}

.container-cards {
  display: flex;
}

.image {
  height: 300px;
  width: 200px;
}

.container-btn {
  width: 100%;
  display: block;
  justify-content: center;
  align-self: end;
}
.btn {
  color: var(--color-01);
  border-radius: 20px;
  cursor: pointer;
  text-transform: uppercase;
  border: none;
  font-weight: 700;
  padding: 5px 25px;
  font-family: var(--font-family-1);
  background-color: var(--color-04) !important;
}

.content-title {
  font-size: 20px;
  display: block;
  font-weight: bold;
  line-height: 20px;
}
.container-text {
  padding: 25px;
}
.paragraph {
  padding: 10px 0 0 0;
  font-size: 10px;
  font-family: var(--font-family-3);
}
.title-options {
  font-size: 20px;
  color: var(--color-07);
  font-weight: 900;
}

.title {
  font-family: var(--font-family-4);
  font-size: 20px;
  color: var(--color-01);
  font-weight: bold;
}
.sub-title {
  font-family: var(--font-family-3);
  font-size: 10px;
  color: var(--color-01);
}

.container-title-options {
  text-align: center;
}
</style>
