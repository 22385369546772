<template>
  <div class="container-banner">
    <div class="container-logo">
      <LogoAlteraBankWhite></LogoAlteraBankWhite>
    </div>

    <div class="container-banner-titles">
      <span class="title">
        Abre una cuenta corriente<br />
        llena de beneficios
      </span>
    </div>
  </div>
  <div class="container-body">
    <div class="container-content">
      <img src="@/assets/check-account.png" class="image" />
      <div class="container-text">
        <p class="content-title">Conoce más sobre la Cuenta Corriente</p>
        <p class="paragraph">
          Esta cuenta te permitirá depositar tu dinero, hacer transferencias a
          terceros, hacer giros y compras a través de tu tarjeta de débito y
          compras por internet.
        </p>
        <div class="container-btn">
          <button class="btn">Beneficios</button>
        </div>
      </div>
    </div>
    <div class="container-title-options">
      <span class="title-options">¿Por qué abrir una Cuenta Corriente?</span>
    </div>

    <div class="container-cards">
      <ImageOption :image="tools"></ImageOption>
      <ImageOption :image="points"></ImageOption>
      <ImageOption :image="noComission"></ImageOption>
    </div>
  </div>

  <div class="container-btn" @click="goTo">
    <button class="btn">Contratar</button>
  </div>
  <div>
    <Footer></Footer>
  </div>
</template>

<script setup>
const tools = {
  title: 'Sin costo de mantención',
  subTitle:
    'Cumpliendo ciertas condiciones que podrás revisar junto a tu ejecutivo/a.',
  option: 'tools',
  image: require('@/assets/tools.png'),
  backgroundColor: 'backgroundColor4',
  containerDisplay: 'containerDisplayFlex',
  containerTextAlign: 'textAlignLeft',
  titleClass: 'title-1',
  containerMargin: 'margin-20',
}

const points = {
  title: 'Acumulas Altera Bank puntos',
  subTitle:
    'Para todas tus compras, que podrás cambiar por productos exclusivos',
  option: 'no comission',
  image: require('@/assets/points.png'),
  backgroundColor: 'backgroundColor5',
  containerDisplay: 'containerDisplayFlex',
  containerTextAlign: 'textAlignLeft',
  titleClass: 'title-1',
  containerMargin: 'margin-20',
}

const noComission = {
  title: 'Tenemos 0% de comisión',
  subTitle:
    'Para compras internacionales que se convierten al tipo de cambio del día',
  option: 'no comission',
  image: require('@/assets/no-comission.png'),
  backgroundColor: 'backgroundColor6',
  containerDisplay: 'containerDisplayFlex',
  containerTextAlign: 'textAlignLeft',
  titleClass: 'title-1',
  containerMargin: 'margin-20',
}
</script>

<script>
import LogoAlteraBankWhite from '@/components/LogoAlteraBankWhite.vue'
import ImageOption from '@/components/ImageOption.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'HomeComponent',
  components: { LogoAlteraBankWhite, ImageOption, Footer },
  methods: {
    goTo: function () {
      this.$router.push('/bank/check-account/open-account')
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-banner {
  height: 250px;
  width: 100%;
  background: url('@/assets/banner-check-account.png') no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  text-align: left;
}

.container-banner-titles {
  display: grid;
  text-align: left;
  padding-bottom: 0px;
  padding-top: 10px;
  padding-left: 20px;
}

.title {
  font-family: var(--font-family-4);
  font-size: 20px;
  color: var(--color-01);
  font-weight: bold;
}

.container-logo {
  padding: 30px 0px 20px 20px;
}
.container-body {
  height: 65%;
  padding: 50px 0px;
}

.container-images {
  display: inline-block;
}
.container-content {
  display: flex;
  height: 50%;
  padding: 50px 50px 0px 50px;
  text-align: center;
}

.container-cards {
  display: flex;
  padding: 0 10px 0 0;
}

.image {
  height: 200px;
  width: 120px;
}

.container-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0px 60px 0px;
}
.btn {
  color: var(--color-01);
  border-radius: 20px;
  cursor: pointer;
  text-transform: uppercase;
  border: none;
  font-weight: bold;
  padding: 5px 25px;
  font-family: var(--font-family-3);
  background-color: var(--color-04) !important;
  font-size: 12px;
}

.content-title {
  font-size: 17px;
  font-family: var(--font-family-3);
  font-weight: bold;
  line-height: 20px;
}
.container-text {
  padding: 10px 30px;
  text-align: justify;
  width: 50%;
}
.paragraph {
  font-size: 10px;
  font-family: var(--font-family-3);
}

.title-options {
  font-size: 20px;
  color: var(--color-07);
  font-weight: bold;
  font-family: var(--font-family-3);
  line-height: 20px;
}

.container-title-options {
  text-align: center;
  padding: 50px;
}
</style>
