<template>
  <div class="container-banner">
    <div class="container-logo">
      <LogoAlteraBankWhite></LogoAlteraBankWhite>
    </div>

    <div class="container-banner-titles">
      <span class="title">!Se parte!</span>
      <span class="sub-title">de nuestro ecosistema de soluciones</span>
    </div>
  </div>
  <div class="container-body">
    <ImageOption :image="cards"></ImageOption>
    <ImageOption :image="money"></ImageOption>
    <ImageOption :image="points"></ImageOption>
  </div>
  <div>
    <Footer></Footer>
  </div>
</template>

<script setup>
const cards = {
  title: 'Abre ahora tu Cuenta Corriente',
  subTitle: 'En pocos minutos, 100% online, con una comisión mensual de $0.',
  option: 'cards',
  image: require('@/assets/cards.png'),
  backgroundColor: 'backgroundColor4',
  containerDisplay: 'containerDisplayGrid',
  containerTextAlign: 'textAlignCenter',
  titleClass: 'title-1',
  goTo: '/bank/check-account/home',
  containerMargin: 'margin-10',
  dinamycUrl: false,
}

const money = {
  title: 'Pide hoy tu avance en efectivo',
  subTitle:
    'Hoy hasta 12 cuotas sin interés y empieza a pagar en tres meses más.',
  option: 'money',
  image: require('@/assets/money.png'),
  backgroundColor: 'backgroundColor5',
  containerDisplay: 'containerDisplayGrid',
  containerTextAlign: 'textAlignCenter',
  titleClass: 'title-1',
  goTo: '/bank/cash-advance/home',
  containerMargin: 'margin-10',
  dinamycUrl: false,
}

const points = {
  title: 'Canjea tus puntos AlteraBank',
  subTitle:
    '¡Y te regalamos 500 puntos extra para que puedas disfrutar lo que más quieres!',
  option: 'points',
  image: require('@/assets/points.png'),
  backgroundColor: 'backgroundColor6',
  containerDisplay: 'containerDisplayGrid',
  containerTextAlign: 'textAlignCenter',
  titleClass: 'title-1',
  goTo: '/bank/points/home',
  containerMargin: 'margin-10',
  dinamycUrl: false,
}
</script>

<script>
import LogoAlteraBankWhite from '@/components/LogoAlteraBankWhite.vue'
import ImageOption from '@/components/ImageOption.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'HomeComponent',
  components: { LogoAlteraBankWhite, ImageOption, Footer },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-banner {
  height: 250px;
  width: 100%;
  background: url('../../assets/banner.png') no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  text-align: left;
}

.container-logo {
  padding: 30px 0px 20px 20px;
}
.container-body {
  height: 65%;
  padding: 50px 0px;
}

.container-banner-titles {
  display: grid;
  text-align: left;
  padding-bottom: 0px;
  padding-top: 10px;
  padding-left: 20px;
}

.title {
  font-family: var(--font-family-4);
  font-size: 30px;
  color: var(--color-01);
  font-weight: bold;
}

.sub-title {
  font-family: var(--font-family-3);
  font-size: 15px;
  color: var(--color-01);
}


</style>
