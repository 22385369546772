import { createApp } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import App from './App.vue'
import Form from '@/views/Form'
import Intro from '@/views/Intro'
import Ending from '@/views/Ending'
import './style.css'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import Toaster from '@meforma/vue-toaster'
import BankHome from '@/views/bank/Home'
import CheckAccount from '@/views/bank/checkAccount/Home'
import OpenAccount from '@/views/bank/checkAccount/OpenAccount'
import CashAdvance from '@/views/bank/cashAdvance/Home'
import CodeSms from '@/views/bank/cashAdvance/CodeSms'
import Advantages from '@/views/bank/cashAdvance/Advantages'
import Points from '@/views/bank/points/Home'
import Resume from '@/views/bank/points/Resume'
import Payment from '@/views/bank/points/Payment'


const routes = [
  { path: '/', component: Intro },
  { path: '/form', component: Form },
  { path: '/ending', component: Ending },
  { path: '/bank/home', component: BankHome },
  { path: '/bank/check-account/home', component: CheckAccount },
  { path: '/bank/check-account/open-account', component: OpenAccount },
  { path: '/bank/cash-advance/home', component: CashAdvance },
  { path: '/bank/cash-advance/code-sms', component: CodeSms },
  { path: '/bank/cash-advance/advantages', component: Advantages },
  { path: '/bank/points/home', component: Points },
  {
    path: '/bank/points/resume/:option/:title1/:title2/:points',
    component: Resume,
  },
  { path: '/bank/points/payment/:option/:ammount', component: Payment },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

const app = createApp(App)
app.use(Toaster)
app.use(router)

app.mount('#app')
