<template>
  <div>
    <img
      src="@/assets/logo-altera-bank-white.png"
     
      class="logo-altera-bank-white"
    />

  </div>
</template>

<script>


export default {

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logo-altera-bank-white {
  width: 150px;
  height: 40px;
}


</style>
