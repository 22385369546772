<template>
  <div id="app">
    <div class="container-logo">
      <LogoAltera></LogoAltera>
    </div>
    <div class="container-title">
      <span class="title">Regístrate</span>
    </div>

    <div class="container-form">
      <form action="">
        <div class="div">
          <label for="name" class="label">Nombre:</label>
          <input type="text" class="input" id="name" name="name" required v-model="form.name"
            v-on:keypress="validateName($event)" />
        </div>
        <div class="div">
          <label for="gsm" class="label">Celular:</label>

          <input type="text" class="input" id="gsm" required v-model="form.gsm"
            v-on:keypress="validatePhoneNumber($event)" maxlength="12" />
        </div>
        <div class="div">
          <label for="email" class="label">E-mail:</label>
          <input type="email" class="input" id="email" required v-model="form.email" />
        </div>
        <div class="container-btn">
          <button class="btn" v-on:click="submitForm()">Continuar</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import LogoAltera from '@/components/LogoAltera.vue'
import { VUE_APP_URL, VUE_APP_URL_DATA } from '@/config/app.config'

export default {
  name: 'FormComponent',
  components: { LogoAltera },
  data: function () {
    return {
      form: {
        name: '',
        gsm: 569,
        email: '',
      },
    }
  },

  methods: {
    sendData() {
      if (!this.validateEmail) {
        this.showToast('Revise el email ingresado')
      }
    },
    async submitForm() {
      axios
        .post(
          `${VUE_APP_URL}/send`,
          {
            to: `${this.form.gsm}`,
            msg: `Gracias ${this.form.name} por vivir la experiencia Altera, ya estás participando por tu premio.`,
            url: `${VUE_APP_URL_DATA}`,
            'short-url': true,
            data: {
              name: this.form.name,
              email: this.form.email,
              gsm: this.form.gsm,
            },
            demo: 'banca',
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
            },
          }
        )
        .then(function (response) {
          console.log(response)
        })
        .catch(function (error) {
          console.log(error)
        })
      this.$router.push({ path: '/ending', replace: true })
    },
    validateName(e) {
      let char = String.fromCharCode(e.keyCode)
      if (/^[a-zA-Z\s]*$/.test(char)) return true
      else e.preventDefault()
    },
    validatePhoneNumber(e) {
      let char = String.fromCharCode(e.keyCode)
      if (/[ +()0-9]+/.test(char)) return true
      else e.preventDefault()
    },

    validateEmail() {
      let emailRegex =
        /^([A-Za-z0-9-_.A+])+@([A-Za-z0-9-_.])+\.([A-Za-z]){2,4}$/
      return emailRegex.test(this.email)
    },
    showToast(mensaje) {
      this.$toast.show(mensaje, {
        position: 'top-right',
        duration: 1500,
        queue: false,
        max: 1,
      })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container-logo {
  margin: 47px auto;
}

.container-title {
  margin: 70px auto 20px auto;
}

.title {
  color: var(--color-01);
  text-transform: uppercase;
  font-size: 28px;
  font-family: var(--font-family-1);
  letter-spacing: 2px;
}

.container-form {
  margin: 0 auto;
}

.container-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5rem;
}

.btn {
  color: var(--color-01);
  background-color: var(--color-03) !important;
  border-radius: 15px;
  cursor: pointer;
  text-transform: uppercase;
  border: none;
  padding: 5px 20px;
  font-family: var(--font-family-1);
  font-size: 35px;
}

.div {
  margin-bottom: 10px;
}

.label {
  display: inline-block;
  width: 110px;
  color: var(--color-01);
  text-align: left;
  padding: 0;
  font-family: var(--font-family-2);
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
}

.input {
  padding: 5px 10px;
  background-color: var(--color-03) !important;
  margin: 9px;
  border-radius: 15px;
  border: none;
  outline: none;
  font-family: var(--font-family-2);
  text-transform: uppercase;
  font-size: 10px;
  width: 220px;
  height: 25px;
}
</style>
