<template>
  <div>
    <img
      src="@/assets/logo-altera-bank-black.png"
     
      class="logo-altera-bank-black"
    />
  </div>
</template>

<script>
export default {}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logo-altera-bank-black {
  width: 130px;
  height: 30px;
}


</style>
